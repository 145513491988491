<template>
  <div class="">
    <div class="row justify-content-between align-items-end">
      <div class="col-2">
        <label for="">Dépôt <span class="text-danger">*</span> </label>
        <select
          v-model="deposit_reference"
          @change="updateFilteredStocks"
          class="form-select"
        >
          <option value="">---</option>
          <option
            v-for="(deposit, index) in deposits"
            :key="index++"
            :value="deposit.reference"
          >
            {{ deposit.fullName }}
          </option>
        </select>
      </div>
      <div class="col-3">
        <label for=""> Désignation de stock </label>
        <select
          v-model="stock_fullName"
          @change="updateFilteredStocks"
          class="form-select"
        >
          <option value="">---</option>
          <option
            v-for="(stockFullName, index) in stockFullNames"
            :key="index++"
            :value="stockFullName.fullName"
          >
            {{ stockFullName.fullName }}
          </option>
        </select>
      </div>
      <div class="col">
        <label for="">Catégorie</label>
        <select
          v-model="section_reference"
          @change="updateFilteredStocks"
          class="form-select"
        >
          <option value="">---</option>
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Produit</label>
        <select
          v-model="item_reference"
          @change="updateFilteredStocks"
          class="form-select"
        >
          <option value="">---</option>
          <option
            v-for="(item, index) in items.filter(
              (p) => p.section_reference == section_reference
            )"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">

      <div class="col-auto">
        <button @click="search()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise fs-5"></i>
          <span> Rechercher </span>
        </button>
      </div>

      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise fs-5"></i>
          <span> Actualiser </span>
        </button>
      </div>

      <div class="col-auto">
        <button @click="print()" class="btn btn-secondary">
          <i class="bi bi-printer fs-5"></i>
          <span> Imprimer </span>
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Désignation</th>
          <th scope="col">Catégorie</th>
          <th scope="col">Stock / DPT</th>
          <th scope="col">Quantité</th>
          <th scope="col">Nº de Chambre</th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody>
        <!-- v-if="stocks.filter((p) => p.item != null) == null" -->
        <tr v-for="(stock, index) in stocks" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            <span v-if="stock">
              {{ stock.item_reference }}
            </span>
          </td>
          <td>
            <span v-if="stock">
              {{ stock.itemFullName }}
            </span>
          </td>

          <td>
            <div v-if="stock">
              {{ stock.sectionFullName }}
            </div>
          </td>

          <td>
            <div v-if="stock">
              {{ stock.fullName }}
            </div>
          </td>
          <td class="col">
            <span
              class="p-2 rounded-2"
              :class="
                stock.quantity == 0
                  ? 'bg-danger'
                  : stock.quantity <= 5
                  ? 'bg-warning'
                  : 'text-success'
              "
            >
              <strong :style="{ fontWeight: 'bolder !important' }">
                {{ stock.quantity }}
              </strong>
              {{ stock.unit }}
            </span>
          </td>

          <td>
            <span v-if="stock.cold_room">
              {{ stock.cold_room.number }}
            </span>
          </td>

          <td class="p-0 m-0">
            <div class="d-flex">
              <button
                @click="
                  $router.push({
                    name: 'stocks-edit',
                    params: { reference: stock.reference },
                  })
                "
                class="btn"
              >
                <i class="bi bi-pencil-square fs-5"></i>
              </button>

              <button
                @click="
                  $router.push({
                    name: 'stocks-delete',
                    params: { reference: stock.reference },
                  })
                "
                class="btn"
              >
                <i class="bi bi-file-x-fill fs-5 text-danger"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      section_reference: "",
      item_reference: "",
      deposit_reference: "",
      filteredStocks: [],
      stock_fullName: "",
    };
  },
  computed: {
    ...mapGetters("stock", {
      stocks: "getStocks",
      stockFullNames: "getStockFullNames",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("deposit", {
      deposits: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("stock/getAll");
    this.$store.dispatch("stock/fetchStockFullNames");
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/getAll");
    this.$store.dispatch("deposit/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("stock/search", value);
    },
    async refresh() {
      this.$store.dispatch("stock/fetchStockFullNames");
      this.$store.dispatch("stock/getAll");
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("deposit/getAll");

      this.search_value = "";
      this.addedToday = false;
      this.sortValue = "";
      this.section_reference = "";
      this.item_reference = "";
      this.deposit_reference = "";
    },

    async sort(value) {
      return await this.$store.commit("stock/sort", value);
    },

    async updateFilteredStocks() {
      // Call the filtering logic again to update the filteredStocks

      // Check if all three filters (item_reference, section_reference, deposit_reference) are present
      if (this.item_reference != "" && this.section_reference != "") {
        // Filter the stocks array based on the selected item_reference
        return (this.filteredStocks = this.stocks.filter((stock) => {
          return stock.item_reference == this.item_reference;
        }));
      }

      // Check if both section_reference and deposit_reference filters are present
      else if (this.section_reference != "") {
        // Filter the stocks array based on either the selected section_reference or deposit_reference
        return (this.filteredStocks = this.stocks.filter((stock) => {
          return stock.section_reference == this.section_reference;
        }));
      }

      // Check if only deposit_reference filter is present
      else if (this.stock_fullName != "") {
        // Filter the stocks array based on the selected deposit_reference
        return (this.filteredStocks = this.stocks.filter((stock) => {
          return stock.fullName == this.stock_fullName;
        }));
      }

      // Return an empty array if none of the filters are present
      else return [];
    },
    async print() {
      let section_reference = this.section_reference;
      let item_reference = this.item_reference;
      let deposit_reference = this.deposit_reference;
      let stock_fullName = this.stock_fullName;
      return await this.$store.dispatch("stock/print", {
        section_reference,
        item_reference,
        deposit_reference,
        stock_fullName,
      });
    },

    async search() {
      let section_reference = this.section_reference;
      let item_reference = this.item_reference;
      let deposit_reference = this.deposit_reference;
      let stock_fullName = this.stock_fullName;
      return await this.$store.dispatch("stock/filter", {
        section_reference,
        item_reference,
        deposit_reference,
        stock_fullName,
      });
    },
  },

  filters: {},
};
</script>
